<template>
    <div>
        <div class="centerBox">
            <!-- 热卖推荐 -->
            <hotSell ref="hotSaleAndAd" :hotSaleAndAd='hotSaleAndAd'/>
            <el-row style="margin-top:16px">
                <!-- 左侧 -->
                <el-col :span='5'>
                    <!-- 商品分类 -->
                    <fenlei />
                    <!-- 商品推广 -->
                    <extension style="margin-top:16px"/>
                </el-col>
                <el-col :span='19'>
                    <!-- 商品列表 -->
                    <commodityList />
                </el-col>
            </el-row>
        </div>
        <div class="footers">
            <!-- <footers/> -->
        </div>
    </div>
</template>

<script>
import footers from "@/components/footers.vue";
import hotSell from "./components/hotSell.vue";
import extension from "./components/extension.vue";
import fenlei from "./components/fenlei.vue";
import commodityList from "./components/commodityList.vue";
export default {
  components: {
    footers,
    hotSell,
    extension,
    fenlei,
    commodityList
  },
  data() {
    return {
      hotSaleAndAd:{}
    }
  },
  created () {
    // this.query()
    if(!sessionStorage.getItem('applicatio_commodity')){
      sessionStorage.setItem("applicatio_commodity", 'true');
    }
    
  },
  methods: {
  
  },
};
</script>

<style lang="scss" scoped>
.centerBox {
  width: 1200px;
  margin: 0 auto;
}
.footers {
  position: fixed;
  bottom: 0;
  width: 100%;
}
</style>