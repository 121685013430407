<template>
  <!-- 商品推广 -->
  <div class="left_template">
    <div class="borderWai">
      <div class="borderBottom">
        <span>商品分类</span>
      </div>
    </div>
    <!-- 分类区域 -->
    <el-tree
      style="margin-top:20px"
      :data="data"
      :props="defaultProps"
      @node-click="handleNodeClick"
    ></el-tree>
  </div>
</template>

<script>
import { getFenlei } from "./getZhuangtai.js";
import eventBus from "@/server/eventBus.js";

export default {
  data() {
    return {
      data: [],
      defaultProps: {
        children: "children",
        label: "label",
        value: "id"
      }
    };
  },
  created() {
    getFenlei("itemsku", 1).then(res => {
      this.data = res;
    });
  },
  methods: {
    handleNodeClick(data) {
      this.getIdFun(data);
      eventBus.$emit("searchFenlei", this.getIdFun(data));
    },
    //  获取id
    getIdFun(data) {
      let arr = [];
      arr.push(data.value);
      if (data.children && data.children.length != 0) {
        this.getChildrenId(data.children);

        arr = [...arr, ...this.getChildrenId(data.children)];
      }

      return arr;
    },
    getChildrenId(data) {
      let arr = [];
      data.forEach(i => {
        arr.push(i.value);
        if (i.children && i.children.length != 0) {
          this.getChildrenId(i.children);
        }
      });
      return arr;
    }
  }
};
</script>

<style lang="scss" scoped>
.left_template {
  padding: 20px;
  width: 188px;
  height: 452px;
  background-color: #fff;
}
.borderWai {
  border-bottom: 1px solid #ccc;
}
.borderBottom {
  display: inline-block;
  border-bottom: 3px solid #f74848;
  span {
    font-size: 18px;
  }
}
</style>