import {
    get
} from '@/server/ajax.js'


function changeJilian(item) {
    let array = [];
    item.forEach((res) => {
        if (res.children) {
            array.push({
                value: res.data.id,
                label: res.title,
                children: changeJilian(res.children),
            });
        } else {
            array.push({
                value: res.data.id,
                label: res.title,
            });
        }
    });
    return array;
}

// 获取分类
export function getFenlei(modular,company_id) {
    let params = {
        modular,
        company_id
    }
    return new Promise((resolve) => {
        get(`/api/setting/public/category/list`,params).then((res) => {
            console.log(res);
            resolve(changeJilian(res.data));
        })
    })

}
// 获取分组
export function getFenzu(model) {
    return new Promise((resolve) => {
        get(`/api/${model}/categoryGroup/group/list`).then((res) => {
            console.log(res);
            resolve(changeJilian(res.data));
        })
    })

}

// ceshishuju
// ceshishuju2
