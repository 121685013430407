<template>
    <!-- 商品推广 -->
    <div class="left_template">
        <div class="borderBottom">
            <span>商品推广</span>
        </div>
        <el-row>
            <el-col v-for="(item,index) in ad" :key="index" style="margin-top:16px" :span="24">
                <div>
                    <el-image @click="details(item)" style="width: 192px; height: 192px" :src="item.cover_url"></el-image>
                </div>
                <el-link @click="details(item)" :underline="false">
                    <p class="yichu">{{item.name||'--'}}</p>
                </el-link>
                <p>
                    <el-link @click="details(item)" :underline="false">
                        <p class="red_16size">¥{{'200'}}</p>
                    </el-link>
                </p>
            </el-col>
        </el-row>
    </div>
</template>

<script>

export default {
  data() {
    return {
      ad: []
    };
  },
  created() {
    this.query();
  },
 
  methods: {
    query() {
      this.$axios.postJSON("/app_shop/goods_center/hotSaleAndAd").then(res => {
        if (res.code == 200) {
          this.ad = res.data.ad;
        }
      });
    },
    details(item) {
      console.log(item);
      this.$router.push({ path: "commodityInfo", query: { id: item } });
    }
  }
};
</script>

<style lang="scss" scoped>
.left_template {
  padding: 20px;
  width: 188px;
  min-height: 300px;
  background-color: #fff;
}
.red_16size {
  font-size: 16px;
  color: #f74848;
  font-weight: bold;
}
.borderBottom {
  display: inline-block;
  border-bottom: 3px solid #f74848;
  span {
    font-size: 18px;
  }
}
.yichu {
  width: 192px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
</style>