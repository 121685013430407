<template>
  <!-- 热卖推荐 -->
  <div class="bottomColor" v-if="flag">
    <div class="borderBottom">
      <span class="red_16size">热卖推荐</span>
    </div>
    <el-carousel indicator-position="none" :interval="5000" arrow="always">
      <el-carousel-item v-for="(item,index) in hotSale" :key="index">
        <!-- 图片商品 -->
        <el-row type="flex" justify="start" style="margin-top:27px">
          <el-col v-for="(itemPic,indexPic) in item" :key="indexPic" style="margin:16px" :span="5">
            <div>
              <el-image @click="details(itemPic)" style="width: 192px; height: 192px" :src="itemPic.cover_url"></el-image>
            </div>
            <el-link :underline="false" @click="details(itemPic)">
              <p class="mt10 yichu">{{itemPic.name||'--'}}</p>
            </el-link>
            <p>
              <el-link :underline="false" @click="details(itemPic)">
                <p
                  class="red_16size mt10"
                >¥{{itemPic.item_sku && itemPic.item_sku.sale_price||'--'}}</p>
              </el-link>
            </p>
          </el-col>
        </el-row>
      </el-carousel-item>
    </el-carousel>
  </div>
</template>

<script>
import _ from "lodash";
export default {
  created() {
    this.query();
  },
  data() {
    return {
      hotSale: {},
      flag:true
    };
  },
  methods: {
    query() {
      this.$axios.postJSON("/app_shop/goods_center/hotSaleAndAd").then(res => {
        if (res.code == 200) {
          if(res.data&&res.data.length == 0 ){
            this.flag = false
          }
          if(res.data&&res.data.hot_sale&&res.data.hot_sale.length == 0){
            this.flag = false
          }
          let hotSale = _.chunk(res.data.hot_sale, 5);
          this.hotSale = hotSale;
          
        }
      });
    },
    details(item){
      this.$router.push({ path: "commodityInfo", query: { id: item } });
    }
  }
};
</script>

<style lang="scss" scoped>
.bottomColor {
  background-color: #fff;
  height: 365px;
  padding: 20px 24px;
}
.borderBottom {
  display: inline-block;
  border-bottom: 3px solid #f74848;
}
.red_16size {
  font-size: 16px;
  color: #f74848;
  font-weight: bold;
}
.mt10 {
  margin-top: 10px;
}
.yichu {
  width: 192px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
</style>